.head-contain {
    background-color: #FFC409;
    padding: 20px 10%
}

.logo-pic {
    width: 10vw;
    min-width: 100px;
    margin-right: 48px;
}

.send-pic {
    width: 13%;
}

.send-contain {
    background-color: #F2F2F2;
    padding: 20px;
    height: 20%;
}

.head-title {
    color: white;
    font-size: 36px;
    font-weight: bold;
}

.send-title {
    color: #010104;
    font-size: 20px;
    white-space: nowrap
}

@media screen and (max-width: 1210px) {
    .head-title {
        font-size: 25px;
    }

    .send-title {
        font-size: 15px;
    }
}

@media screen and (max-width: 768px) {
    .head-contain {
        position: relative;
        justify-content: space-around;
    }

    .send-contain {
        justify-content: center;
    }
}

@media screen and (max-width: 768px) {
    .head-contain {
        position: relative;
        justify-content: space-around;
    }

    .send-contain {
        justify-content: center;
    }
}

@media screen and (max-width: 600px) {
    .head-contain {
        flex-direction: column-reverse;
        align-items: start;
    }

    .send-contain {
        justify-content: center;
    }
}