.flexLayout {
    display: flex;
}

.flexHor {
    display: flex;
    flex-direction: 'row';
}

.flexEnd {
    display: flex;
    justify-content: flex-end;
}

.horArrange {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.flexBetween {
    display: flex;
    justify-content: space-between;
}

.primary-color {
    color: #134775
}

.background-primary-color {
    background-color: #134775;
}

.justifyCenter {
    display: flex;
    justify-content: center;
    align-items: center;
}

.white-back-color {
    background-color: white;
}

.primary-border {
    border-radius: 8px;
}

.alignCenter {
    display: flex;
    align-items: center;
}