.menu-item {
    padding: 10px;
    text-align: center;
    color: white;
    cursor: pointer;
    font-size: 2rem;
}

.title {
    font-size: 1.5rem;
    font-weight: bold;
}

.add-more-btn {
    border: 1px dashed black;
    padding: 10px;
    text-align: center;
    cursor: pointer;
    margin-top: 20px;
}

.count {
    width: 80px;
    margin-right: 5px;
}

.crop.count {
    width: 200px;
}

.line-item {
    padding: 10px;
    border-bottom: 1px solid grey;
    justify-content: space-between;
}

.line-item select {
    width: 100%;
}

.line-item input {
    width: 100%;
    height: 56px;
    font-size: 3rem;
    text-align: center;
}