.form-contain {
    padding: 0 10%;
}

.form-header {
    margin: 18px 0;
}

.form-body {
    padding: 0 30px;
}

table {
    width: 100%;
}

table tbody tr td {
    border-bottom: 10px solid white;
}

table thead tr th:nth-child(6) {
    position: relative;
}

table thead tr th:nth-child(6)::after {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: -2px;
    width: 10px;
    height: calc(100% + 10px);
    background-color: white;
}

table tbody tr td:nth-child(6) {
    position: relative;
}

table tbody tr td:nth-child(6)::after {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: -2px;
    width: 10px;
    height: calc(100% + 10px);
    background-color: white;
}

th {
    color: #134775;
    font-weight: 500;
    text-align: start;
    height: 100px;
    padding-left: 20px;
}

td {
    text-align: start;
    height: 72px;
    color: #134775;
    font-size: 24px;
    background-color: #eaeaea;
    padding-left: 20px;
}

.table-input {
    width: 72px;
    height: 56px;
    border: none;
    margin-right: 10px;
    border: 1px solid #c5c5c5;
    text-align: center;
    color: #134775;
    font-size: 24px;
}

.table-input-con {
    position: relative;
}

.table-input-hint {
    position: absolute;
    z-index: 9;
    font-size: 14px;
    padding: 5px 10px;
    width: 150px;
    background-color: rgba(0, 0, 0, 0.5);
    color: white;
    bottom: -56px;
    display: none;
}

.table-input:hover + .table-input-hint {
    display: block;
}

input:focus {
    outline: none;
}

.select-style {
    border: none;
    outline: none;
    width: 220px;
    height: 56px;
    font-size: 24px;
    text-align: left;
    color: #134775;
    padding-left: 10px;
    -webkit-appearance: none;
}

select {
    background-position: right;
    background-repeat: no-repeat;
}

.reduce-button {
    border: none;
    outline: none;
    padding: 0;
}

@media screen and (max-width: 1250px) {
    .form-body {
        overflow-x: scroll;
    }

    .body-title {
        width: auto;
    }

    .form-contain {
        padding: 0 5%;
    }
}

.hide-weeding {
    display: none !important;
}
