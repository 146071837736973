.App {
    background-color: #F1F2F5;
    padding-bottom: 30px;
    min-height: 100vh;
}

.form-header-title {
    padding: 10px 3%;
    width: 100%;
}

.form-header-title p {
    color: #134775;
    font-size: 20px;
    font-weight: 600;
    text-align: center;
}

.head-input {
    height: 56px;
    width: 80px;
    border: 1px solid #C5C5C5;
    margin: 0 20px 0 80px;
    text-align: center;
    color: #134775;
    font-size: 24px;
}

.action-btn {
    width: 64px;
    cursor: pointer;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
}

input[type="number"] {
    -moz-appearance: textfield;
}

@media screen and (min-width: 1550px) {
    .top-con {
        width: 33%;
    }
}

@media screen and (max-width: 1000px) {
    .form-header-title {
        flex-direction: column;
    }
}

@media screen and (min-width:300px) and (max-width: 500px) {
    .form-header-title p {
        font-size: 15px;
    }

    .form-header-title {
        height: 100%;
    }

    .head-input {
        margin-right: 10px;
        width: 33%;
        min-width: 50px;
        font-size: 20px;
        height: 45px;
    }
}

.loading {
    position: absolute;
    text-align: center;
    width: 100vw;
    font-size: 18px;
    font-weight: bold;
    left: 0;
}

.question-mark {
    position: relative;
}

.question-mark .tooltiptext {
    visibility: hidden;
    background-color: rgba(0, 0, 0, 0.6);
    top: -40px;
    width: 250px;
    color: #fff;
    text-align: center;
    padding: 5px 10px;
    border-radius: 6px;
    /* Position the tooltip text - see examples below! */
    position: absolute;
    z-index: 1;
}

.question-mark:hover .tooltiptext {
    visibility: visible;
}

.question-mark::before {
    content: '?';
    position: absolute;
    width: 36px;
    font-size: 24px;
    height: 36px;
    background-color: grey;
    border-radius: 50%;
    text-align: center;
    color: white;
    right: -60px;
    top: 14px
}